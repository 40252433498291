import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, RouterModule } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';

import { routes } from './app.routes';
import { provideHttpClient } from '@angular/common/http';
import { NX_MODAL_SCROLL_STRATEGY, NxDialogService } from '@aposin/ng-aquila/modal';
import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';

function scrollStrategyFactory(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.close({ threshold: 100 });
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(RouterModule.forRoot(routes, {
      useHash: window.location.host.includes('fox.25sprout'),
      bindToComponentInputs: true,
      scrollPositionRestoration: 'enabled'
    })),
    provideAnimations(),
    provideHttpClient(),
    NxDialogService,
    {
      provide: NX_MODAL_SCROLL_STRATEGY,
      useFactory: scrollStrategyFactory,
      deps: [Overlay],
    },
  ],
};

