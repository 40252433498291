import { Routes } from '@angular/router';

// Constants
import { routePath } from 'src/app/contants/route-path.const';

export const routes: Routes = [
  {
    path: routePath.home,
    loadChildren: () => import('./routes/home.routes'),
  },
  {
    path: routePath.analysis,
    loadChildren: () => import('./routes/analysis.routes'),
  },
  {
    path: routePath.article,
    loadChildren: () => import('./routes/article.routes'),
  },
  {
    path: routePath.account,
    loadChildren: () => import('./routes/account.routes'),
  },
];
