import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {
  /**
   * 螢幕寬度是否小於 704px
   *
   * @memberof ResizeService
   */
  private isMobile = false;

  public isMobileSubject = new BehaviorSubject<boolean>(false);

  constructor() { }

  public checkIsMobile(width: number) {
    if (this.isMobile !== width < 704) {
      this.isMobileSubject.next(width < 704);
    }
    this.isMobile = width < 704
  }

  /**
   * 訂閱RWD狀態
   *
   *
   * @readonly
   * @type {Observable<boolean>}
   * @memberof ResizeService
   */

  get isMobile$(): Observable<boolean> {
    return this.isMobileSubject.asObservable();
  }
}
