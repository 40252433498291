import { Component, HostListener, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ResizeService } from './service/resize/resize.service';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'allianz-health-analysis';

  /**
   * 根據螢幕寬度調整樣式
   *
   * @param {{ target: { innerWidth: number } }} event
   * @memberof AppComponent
  */
  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number } }) {
    this.resizeService.checkIsMobile(event.target.innerWidth);
  }

  constructor(
    public resizeService: ResizeService
  ) {}

  ngOnInit(): void {
    this.resizeService.checkIsMobile(window.innerWidth);
  }
}
