/**
 * 路由名稱之定義檔
 */
export const routePath = Object.freeze({
  /**
   * 入口頁
   */
  home: '',

  /**
   * 互動頁
   */
  analysis: 'analysis',

  /**
   * 互動結果頁
   */
  result: 'result',

  /**
   * 文章頁列表
   */
  article: 'article',

  /**
   * 雙帳戶介紹頁
   */
  account: 'account',
});
